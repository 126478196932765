import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import ReactSlick from 'react-slick';
import Chevron from 'Assets/svg/chevron.svg';
import s from './SlickSlider.module.scss';

const SlickSlider = ({items, Card, width, progressBar}) => {
    const slidesToShow = width === 'full' ? 1 : 3;
    const dots = progressBar;

    let responsive = null;
    if(width !== 'full') {
        responsive = [{
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            },
        }];
    }

    const options = {
        slidesToShow: slidesToShow,
        dots: dots,
        infinite: false,
        prevArrow: <Button isNext={false} />,
        nextArrow: <Button isNext={true} />,
        responsive: responsive,
    };

    const classes = classNames(
        s['SlickSlider'],
        {[s['SlickSlider--ProgressBar']]: progressBar},
        {[s[`SlickSlider--${_.upperFirst(width)}`]]: width},
    );

    return (
        <ReactSlick className={classes} {...options}>
            {items.map((item, index) => (
                <li className={s['SlickSlider__Item']} key={index}>
                    <Card {...item} />
                </li>
            ))}
        </ReactSlick>
    );
};

SlickSlider.propTypes = {
    items: PropTypes.array.isRequired,
    Card: PropTypes.elementType.isRequired,
    width: PropTypes.string,
    progressBar: PropTypes.bool,
};

SlickSlider.defaultProps = {
    items: [],
    Card: null,
    width: 'third',
    progressBar: false,
};

const Button = (props) => {
    const {isNext, className: extraClasses, style, onClick} = props;
    const {t} = useTranslation();
    const title = isNext ? t('slickSlider.next') : t('slickSlider.previous');
    const classes = classNames(
        s['SlickSlider__Button'],
        {[s['SlickSlider__Button--Next']]: isNext},
        {[s['SlickSlider__Button--Prev']]: !isNext},
        extraClasses,
    );
    return (
        <button className={classes} style={{...style}} onClick={onClick}>
            <Chevron />
            <span className={'sr-only'}>{title}</span>
        </button>
    );
};

Button.propTypes = {
    isNext: PropTypes.bool,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    style: PropTypes.object,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    isNext: false,
    className: '',
    style: {},
    onClick: null,
};

export default SlickSlider;
