import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import SlickSlider from 'Components/SlickSlider';
import Button from 'Components/Button';
import s from './Slider.module.scss';

const Slider = ({title, text, items, button, Card, emptyMsg}) => {

    const sanitizedTitle = sanitizeText(title);
    const sanitizedText = sanitizeText(text);

    const isEmpty = _.isEmpty(items);

    const classes = classNames(
        s['Slider'],
        {[s['Slider--Empty']]: isEmpty},
    );

    return (
        <div className={classes}>
            <div className={s['Slider__Header']}>
                <div className={s['Slider__HeaderContent']}>
                    <h2
                        className={s['Slider__Title']}
                        dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                    />

                    {!_.isEmpty(text) &&
                        <p
                            className={s['Slider__Text']}
                            dangerouslySetInnerHTML={{__html: sanitizedText}}
                        />
                    }
                </div>

                {!isEmpty &&
                    <div className={s['Slider__Placeholder']} />
                }
            </div>

            {isEmpty && !_.isEmpty(emptyMsg) ? (
                <div className={s['Slider__Empty']}>
                    {emptyMsg}
                </div>
            ) : (
                <div className={s['Slider__Slider']}>
                    <SlickSlider
                        items={items}
                        Card={Card}
                    />
                </div>
            )}

            {!_.isEmpty(button) &&
                <div className={s['Slider__Footer']}>
                    <Button
                        className={s['Slider__Button']}
                        modifier="PrimaryLink"
                        {...button}
                    />
                </div>
            }
        </div>
    );
};

Slider.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    items: PropTypes.array.isRequired,
    button: PropTypes.object,
    Card: PropTypes.elementType.isRequired,
    emptyMsg: PropTypes.string,
};

Slider.defaultProps = {
    title: '',
    text: '',
    items: [],
    button: null,
    Card: null,
    emptyMsg: '',
};

export default Slider;
