import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import s from './CardArticle.module.scss';

const CardArticle = ({title, link, excerpt, modifier}) => {
    const {url, target} = link;

    const sanitizedTitle = sanitizeText(title);
    const sanitizedExcerpt = sanitizeText(excerpt);

    const classes = classNames(
        s['CardArticle'],
        {[s[`CardArticle--${modifier}`]]: modifier},
    );
    return(
        <article className={classes}>
            <span className={s['CardArticle__Shell']} />

            <a className={s['CardArticle__Link']} href={url} target={target}>
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            </a>

            <div className={s['CardArticle__Content']}>
                <h3
                    className={s['CardArticle__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />

                {!_.isEmpty(excerpt) &&
                    <p
                        className={s['CardArticle__Text']}
                        dangerouslySetInnerHTML={{__html: sanitizedExcerpt}}
                    />
                }

                <div className={s['CardArticle__Footer']}>
                    <Button {...link} />
                </div>
            </div>
        </article>
    );
};

CardArticle.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
    excerpt: PropTypes.string,
    modifier: PropTypes.string,
};

CardArticle.defaultProps = {
    title: '',
    link: {},
    excerpt: '',
    modifier: '',
};

const Button = ({title, url, target}) => {
    return (
        <a
            className={s['CardArticle__Button']}
            href={url}
            target={target}
        >{title}</a>
    );
};

Button.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
};

Button.defaultProps = {
    title: '',
    url: '',
    target: '',
};

export default CardArticle;
