import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'Components/Slider';
import CardArticle from 'Components/CardArticle';
import s from './ArticleSlider.module.scss';

const ArticleSlider = ({title, items}) => {
    const itemsWithModifiers = [...items].map((item, index) => {
        const modulus = index % 4;
        item['index'] = index;
        item['modulus'] = modulus;
        item['modifier'] = modulus === 0 ? 'First' : (
            modulus === 1 ? 'Second' : (modulus === 2 ? 'Third' : 'Fourth')
        );
        return item;
    });

    return (
        <div className={s['ArticleSlider']}>
            <div className={s['ArticleSlider__Wrap']}>
                <div className={s['ArticleSlider__Slider']}>
                    <Slider
                        items={itemsWithModifiers}
                        Card={CardArticle}
                        title={title}
                    />
                </div>
            </div>
        </div>
    );
};

ArticleSlider.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
};

ArticleSlider.defaultProps = {
    title: '',
    items: [],
};

export default ArticleSlider;
